import React, { Component } from "react";
import {
  addFile,
  deleteFile,
  deletePage,
  deleteTeam,
  getAllCompetitions,
  getAllFiles,
  getAllTeams
} from "../../functions/postFunctions";
import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import AddTeam from "../../components/AddTeam/AddTeam";
import EditTeam from "../../components/EditTeam/EditTeam";
import { TeamPlaceholder } from "../../components/icons/icons";
import AddFile from "../../components/AddFile/AddFile";

class FilesPage extends Component {
  state = {
    theads: ["Ime", "Akcije"],
    files: [],
    showAddTeam: false,
    showEditTeam: false,
    teamForEdit: null,
    completed: 0,
    uploadComplete: false,
    showUploadPopup: false
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Fajlovi");
    getAllFiles().then(
      res => res.success && this.setState({ files: res.files })
    );
  }

  handleDeletePage = id => {
    deleteFile(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ files: res.files });
      }
    });
  };

  showModal = (name, id) => {
    let team;
    if (id) {
      const { teams } = this.state;
      team = teams.find(e => e.id === id);
    }
    this.setState({ [name]: true, teamForEdit: team });
  };

  hideModal = () => {
    this.setState({
      showAddTeam: false,
      showEditTeam: false,
      teamForEdit: null
    });
  };

  addTeamToList = team => {
    this.setState(prevState => ({
      teams: [...prevState.teams, team],
      showAddTeam: false
    }));
  };

  refreshList = () => {
    getAllTeams().then(res =>
      this.setState({
        teams: res.teams,
        showEditTeam: false,
        teamForEdit: null
      })
    );
  };

  fileInput = React.createRef();

  handleFileInput = () => {
    const config = {
      onUploadProgress: progressEvent => {
        const completed = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.setState({
          completed,
          uploadComplete: completed === 100
        });
      }
    };
    this.setState({ showUploadPopup: true });
    const data = new FormData();
    data.append("file", this.fileInput.files[0]);
    addFile(data, this.props.token, config).then(res => {
      if (res.success) {
        this.setState({ files: [], showUploadPopup: false }, () =>
          this.setState({ files: res.files })
        );
      }
    });
  };

  render() {
    const { theads, files, teamForEdit } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div>
        <div
          className={`errorPopup ${
            this.state.showUploadPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-center flex-column">
            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
              Upload u toku
            </h3>
            <div className="uploadBar d-flex align-items-center p-r">
              <div className="barWrapper">
                <span
                  className="bar d-flex align-items-center justify-content-center"
                  style={{ width: `${this.state.completed}%` }}
                />
              </div>
              <span className="outerProgress f-s-16 center-a-b text-color-primary">
                {this.state.completed}%
              </span>
            </div>
          </div>
        </div>
        <div className="pageTop mb-40">
          <CustomButton onClick={() => this.fileInput.click()}>
            Dodaj fajl
          </CustomButton>
          <input
            style={{ display: "none" }}
            type="file"
            ref={fileInput => (this.fileInput = fileInput)}
            onChange={e => this.handleFileInput(e)}
          />
        </div>
        <Table theads={theads}>
          {files.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <span className="f-s-16">{e.name}</span>
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>
                  <CustomButton
                    onClick={() => this.handleDeletePage(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    );
  }
}

export default FilesPage;
