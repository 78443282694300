import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import React, { Component } from "react";
import {
  deleteProduct,
  getAllProductCategory,
  getAllProducts,
  toggleSpecialOffer
} from "../../functions/postFunctions";

import { CustomInput } from "../../components/Form/Inputs/Inputs";
import Pagination from "react-js-pagination";
import SearchableDropdownMultiple from "../../components/Form/Dropdown/SearchableDropdownMultiple";
import SortProducts from "../../components/SortProducts/SortProducts";
import Table from "../../components/Table/Table";

class ProductsPage extends Component {
  state = {
    theads: ["Cover", "Naziv", "Jedinica mjere", "Specijalna ponuda", "Akcije"],
    products: [],
    removeLoadMore: true,
    page: 1,
    categoryList: [],
    currentCategory: null,
    sortProductsModal: false,
    sortableCategories: [],
    activePage: 1,
    max: 0,
    product_code: "",
    modalBtn: false,
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Proizvodi");

    if (parseInt(this.props.match.params.page) < 1) {
      this.props.history.push("1");
    }

    let categories = [];
    let sortableCategories = [];
    const handleRecursion = arr => {
      arr.forEach(e => {
        categories.push({ value: e.id, label: e.name });
        if (e.children.length > 0) {
          handleRecursion(e.children);
        } else {
          if (e.id !== 290) {
            sortableCategories.push(e);
          }
        }
      });
    };

    getAllProductCategory().then(res => {
      if (res.success) {
        handleRecursion(res.categories);
        const categories = this.findAllMenus(res.categories);
        this.setState({
          categoryList: categories,
          currentCategory: {
            value: 0,
            label: "Svi proizvodi"
          },
          sortableCategories,
          activePage: parseInt(this.props.match.params.page)
        });
      }
    });
  }

  findAllMenus = array => {
    let menus = [];
    const handleRecursion = arr => {
      arr.forEach(e => {
        menus.push(e);
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };
    handleRecursion(array);
    return menus;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentCategory !== this.state.currentCategory &&
      this.state.currentCategory
    ) {
      this.loadNews();
    }
    if (prevProps.match.params.page !== this.props.match.params.page) {
      this.setState({ activePage: parseInt(this.props.match.params.page) });
      this.loadMoreNews();
    }
    if (prevState.product_code !== this.state.product_code) {
      console.log(prevState.product_code)
      this.loadNews();
      this.props.history.push("1");
    }
  }

  handleDeletePage = id => {
    const { products } = this.state;
    const newProducts = products.filter(e => e.id !== id);
    deleteProduct(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ products: newProducts });
        this.setState(prevState => ({modalBtn: !prevState.modalBtn}));
      }
    });
  };

  loadNews = () => {
    const { currentCategory, product_code } = this.state;
    getAllProducts(
      20,
      (parseInt(this.props.match.params.page) - 1) * 20,
      currentCategory.value,
      product_code !== "" ?  product_code : null
    ).then(res => {
      console.log(res.products);
      if (res.success) {
        this.setState({
          products: res.products,
          max: res.products.length > 0 ? res.total : 0,
          page: 1
        });
      }
    });
  };

  loadMoreNews = () => {
    const { page, currentCategory } = this.state;
    getAllProducts(
      20,
      (parseInt(this.props.match.params.page) - 1) * 20,
      currentCategory.value
    ).then(res => {
      if (res.success) {
        this.setState(({ products, page }) => ({
          products: res.products,
          page: page + 1,
          max: res.products.length > 0 ? res.total : 0
        }));
      }
    });
  };

  handleInput = (name, value) => {
    if (name === "currentCategory") {
      this.props.history.push("1");
    }
    this.setState({
      [name]: value
    });
  };

  toggleModal = () => {
    this.setState(
      ({ sortProductsModal }) => ({
        sortProductsModal: !sortProductsModal
      }),
      () => {
        if (!this.state.sortProductsModal) {
          this.loadNews();
        }
      }
    );
  };

  handleCheckboxChange = (id, index) => {
    this.setState(
      ({ products }) => ({
        products: [
          ...products.slice(0, index),
          {
            ...products[index],
            special_offer: !products[index].special_offer
          },
          ...products.slice(index + 1)
        ]
      }),
      () => {
        toggleSpecialOffer(
          id,
          this.state.products[index].special_offer,
          this.props.token
        ).then(res => null);
      }
    );
  };

  handlePageChange = pageNumber => {
    this.props.history.push(`${pageNumber}`);
  };

  redirectAddProduct = () => {
    this.props.history.push("/dodaj-proizvod");
  };
  render() {
    const {
      theads,
      products,
      categoryList,
      currentCategory,
      sortProductsModal
    } = this.state;
    localStorage.setItem("page", this.state.activePage);
    let options = [];
    let option = null;
    categoryList.forEach(e => {
      if (e.level === 0) {
        option = null;
        option = {
          label: e.name,
          options: [{ value: e.id, label: e.name }]
        };
        if (option) {
          options.push(option);
        }
      } else {
        option.options.push({ value: e.id, label: e.name });
      }
    });
    const categories = [
      {
        value: 0,
        label: "Svi proizvodi"
      },
      ...options
    ];

    return (
      <div>
        <SortProducts
          hideModal={this.toggleModal}
          visible={sortProductsModal}
          sortableCategories={options.filter(e => e.label !== "Abacus")}
          token={this.props.token}
        />
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-md-3">
              <SearchableDropdownMultiple
                data={categories}
                placeholder="Kategorija"
                name="currentCategory"
                handleChange={this.handleInput}
                value={currentCategory}
              />
            </div>
            <div className="col-md-3">
              <CustomInput
                label="naziv proizvoda"
                value={this.state.product_code}
                handleChange={this.handleInput}
                name="product_code"
                index={0}
                fast
              />
            </div>
            <div className="col-md-6">
              <CustomButton
                onClick={this.redirectAddProduct}
                className="mr-20 green"
              >
                Dodaj proizvod
              </CustomButton>
              <CustomButton onClick={this.toggleModal}>
                Sortiraj proizvode
              </CustomButton>
            </div>
          </div>
        </div>
        <Table theads={theads}>
          {products.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <img
                    src={`/${e.cover}`}
                    alt={e.slug}
                    style={{ maxHeight: "50px" }}
                  />
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.name}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">
                    {e.unit === 0 ? "Komad" : "Kutija"}
                  </span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">
                    <input
                      type="checkbox"
                      checked={this.state.products[index].special_offer}
                      onChange={() => this.handleCheckboxChange(e.id, index)}
                    />
                  </span>
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>

                <div
                    className={`addTeamModal d-flex justify-content-center align-items-center ${
                      this.state.modalBtn ? "visible" : ""
                    }`} style={{overflowY: 'auto'}} >
                        <div className="addTeamContent py-30 px-30">
                          <h4 className="text-color-primary f-s-20 lh text-center mb-20">
                          Da li ste sigurni?
                          </h4>
                  
                          <div className="row mt-40">
                            <div className="col-lg-12 d-flex justify-content-center">
                              <CustomButton
                                className="mr-30 green"
                                onClick={() => this.handleDeletePage(e.id)}
                               
                              >
                                Potvrdi
                              </CustomButton>
                              
                              <CustomButton onClick={(e) => {this.setState(prevState => ({modalBtn: !prevState.modalBtn}));}}>
                                Odustani
                              </CustomButton>
                            </div>
                        </div>
                  
                        </div>
                    </div>
                    
                  <LinkButton
                    href={`/izmjeni-proizvod/${e.id}`}
                    className="mr-20"
                  >
                    Izmjeni
                  </LinkButton>
                  <CustomButton
                   /* onClick={() => this.handleDeletePage(e.id)}*/
                    onClick={(e) => {this.setState(prevState => ({modalBtn: !prevState.modalBtn}));}}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>



                </td>
              </tr>
            );
          })}
            
        </Table>
        <div className="wrapper mt-40 d-flex justify-content-center">
          <Pagination
            hideDisabled
            activePage={this.state.activePage}
            itemsCountPerPage={20}
            totalItemsCount={this.state.max}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default ProductsPage;
